

.tricity_aboutus_heading {
    text-align: center;
    font-size: 12em;
    padding: 0.3em;
    color: #fff;
    background: #000;
  }
  .timeline {
    position: relative;
    padding: 0;
    list-style: none;
  }
  .timeline:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    content: "";
    background-color: #e9ecef;
  }
  .timeline > li {
    position: relative;
    min-height: 50px;
    margin-bottom: 50px;
  }
  .timeline > li:after,
  .timeline > li:before {
    display: table;
    content: " ";
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .timeline > li .timeline-image {
    position: absolute;
    z-index: 100;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    text-align: center;
    color: white;
    border: 7px solid #e9ecef;
    border-radius: 100%;
    background-color: #000;
  }
  .timeline > li .timeline-image h4,
  .timeline > li .timeline-image .h4 {
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
  }
  .timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }
  .timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }
  .rounded-circle {
    border-radius: 50% !important;
    aspect-ratio: 1;
    object-fit: cover;
  }
  .timeline > li:last-child {
    margin-bottom: 0;
  }
  .timeline .timeline-heading h4,
  .timeline .timeline-heading .h4 {
    margin-top: 0;
    color: inherit;
  }
  .timeline .timeline-heading h4.subheading,
  .timeline .timeline-heading .subheading.h4 {
    text-transform: none;
  }
  .timeline .timeline-body > ul,
  .timeline .timeline-body > p {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    .tricity_aboutus_heading { 
     font-size: 6em; 
    }

  }
  @media (min-width: 768px) {
    .timeline:before {
      left: 50%;
    }
    .timeline > li {
      min-height: 100px;
      margin-bottom: 100px;
    }
    .timeline > li .timeline-panel {
      float: left;
      width: 41%;
      padding: 0 20px 20px 30px;
      text-align: right;
    }
    .timeline > li .timeline-image {
      left: 48%;
      width: 150px;
      height: 150px;
      margin-left: -50px;
    }
    .timeline > li .timeline-image h4,
    .timeline > li .timeline-image .h4 {
      font-size: 13px;
      line-height: 18px;
      margin-top: 16px;
    }
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
      padding: 0 30px 20px 20px;
      text-align: left;
    }
 
    }

  .page-section {
    padding: 6rem 0;
  }

  .container-fluid {
    max-width: 1320px;
  }
  section.tricity_aboutus {
margin-top: 5em;
}
  /*login nd signup form in mobile view */
  @media only screen and (max-width: 920px){
.loginScreen  .social-container  {
display: none;
}
.signupScreen  .social-container  {
display: none;
}
.loginScreen h1 , .signupScreen h1{
    display:none ;
}
.loginScreen span  , .signupScreen span{
    display: none;
}
  }
  .pwd i.fa.fa-eye-slash, 
  .pwd i.fa.fa-eye {
    float: right;
    position: relative;
    margin-top: -42px;
    margin-right: 44px;
}
  .error-msg{
    color: red;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'serif';
    margin-right: auto;
  }
.error{
  border: 2px solid red !important ;
  /* border-color: red; */
  animation: shake 0.5s ;
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-10px); /* Adjust the distance the input field shakes */
  }
  50% {
    transform: translateX(10px); /* Adjust the distance the input field shakes */
  }
}

.dropdown-menu.show {
 left: -60px;
}
.caret::after {
  display: none !important;
}
/* .dropdown-item{
  color: white;
} */